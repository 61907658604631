import React, { Fragment, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

import TvAdsSection from "../components/Layout/TvAdsSection"
import Footer from "../components/Layout/Footer"
import Testimonials from "../components/Layout/Testimonials"
import SeoComp from "../components/SeoComp"
import { pageViewEventScript } from "../utils/additionalScriptHelpers"
import LyneHomeBanner from "../components/Layout/LyneHomeBanner"
import LyneUpHomeBanner from "../components/Layout/LyneUpHomeBanner"
import ProductHomeSection from "../components/Layout/ProductHomeSection"
import InnovationHomeSection from "../components/Layout/InnovationHomeSection"
import ReviewHomeSection from "../components/Layout/ReviewHomeSection"
import ExpertHomeSection from "../components/Layout/ExpertHomeSection"
import ResultHomeSection from "../components/Layout/ResultHomeSection"
import InstagramHomeSection from "../components/Layout/InstagramHomeSection"
import HomeVideoBanner from "../components/Layout/HomeVideoBanner"
import TestimonialSection from "../components/Layout/TestimonialSection"
import BlackFridayMatelasVideoBanner from "../components/Layout/BlackFridayMatelasVideoBanner"
import BlackFridayHomeVideoBanner from "../components/Layout/BlackFridayHomeVideoBanner"
import ChristmasHomeSection from "../components/Layout/ChristmasHomeSection"
import HomeChristmasSection from "../components/Layout/HomeChristmasSection"
import MatelasHomeVideoBanner from "../components/Layout/MatelasHomeVideoBanner"
import VentesProductSection from "../components/Layout/VentesProductSection"
import VentesLyneSection from "../components/Layout/VentesLyneSection"
import VentesLyneProductSection from "../components/Layout/VentesLyneProductSection"
import VentesChaiseSection from "../components/Layout/VentesChaiseSection"
import VentesMatelasSection from "../components/Layout/VentesMatelasSection"
import VentesLoopSection from "../components/Layout/VentesLoopSection"
import VentesMatelasProductSection from "../components/Layout/VentesMatelasProductSection"
import VentesSommierSection from "../components/Layout/VentesSommierSection"
import HomeSatisfactionSection from "../components/Layout/HomeSatisfactionSection"
import TvAdsHomeSection from "../components/Layout/TvAdsHomeSection"
import BottomHomeSection from "../components/BottomHomeSection"
import HomeFlashSection from "../components/Layout/HomeFlashSection"

const Home = ({ intl, data }) => {
  useEffect(() => {
    data.allDirectusSeoTranslation.nodes[0] &&
      data.allDirectusSeoTranslation.nodes[0].meta_title &&
      pageViewEventScript(
        data.allDirectusSeoTranslation.nodes[0].meta_title,
        intl.locale
      )
  }, [])

  return (
    <Fragment>
      <SeoComp
        description={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].meta_description
        }
        title={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].meta_title
        }
        twitter_card={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].twitter_card
        }
        image={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].image &&
          data.allDirectusSeoTranslation.nodes[0].image.data &&
          data.allDirectusSeoTranslation.nodes[0].image.data.url
        }
        hreflangPathname={{ fr: "", en: "", de: "", es: "" }}
      />
      {process.env &&
        (process.env.GATSBY_ACTIVE_ENV === "eur" ||
          process.env.GATSBY_ACTIVE_ENV === "general") &&
        intl.locale === "fr" && (
          <Fragment>
            {data.allDirectusNewHomePageTranslation.nodes[0] && (
              <MatelasHomeVideoBanner
                data={data.allDirectusNewHomePageTranslation.nodes[0]}
              />
            )}

            {/* {data.allDirectusNewHomePageTranslation.nodes[0] && (
              <BlackFridayMatelasVideoBanner
                data={data.allDirectusNewHomePageTranslation.nodes[0]}
              />
            )} */}

            {/* {data.allDirectusChristmasBannerTranslation.nodes[0]
              .christmas_section &&
              data.allDirectusGiftCardPageTranslation.nodes[0]
                .gift_card_popup && (
                <HomeChristmasSection
                  data={
                    data.allDirectusChristmasBannerTranslation.nodes[0]
                      .christmas_section
                  }
                  popupData={
                    data.allDirectusGiftCardPageTranslation.nodes[0]
                      .gift_card_popup
                  }
                />
              )} */}

            {/* {data.allDirectusChristmasBannerTranslation.nodes[0]
              .christmas_lyne_section && (
              <ChristmasHomeSection
                data={
                  data.allDirectusChristmasBannerTranslation.nodes[0]
                    .christmas_lyne_section
                }
                direction="reverse"
                section="lyneup"
              />
            )} */}

            {data.allDirectusVenteExclusiveTranslation.nodes[0]
              .product_section && (
              <VentesProductSection
                data={
                  data.allDirectusVenteExclusiveTranslation.nodes[0]
                    .product_section
                }
              />
            )}

            {data.allDirectusVenteExclusiveTranslation.nodes[0]
              .chaise_section && (
              <VentesChaiseSection
                data={
                  data.allDirectusVenteExclusiveTranslation.nodes[0]
                    .chaise_section
                }
              />
            )}

            {data.allDirectusVenteExclusiveTranslation.nodes[0]
              .lyne_section && (
              <VentesLyneSection
                data={
                  data.allDirectusVenteExclusiveTranslation.nodes[0]
                    .lyne_section
                }
              />
            )}

            {data.allDirectusVenteExclusiveTranslation.nodes[0]
              .lyne_product_section && (
              <VentesLyneProductSection
                data={
                  data.allDirectusVenteExclusiveTranslation.nodes[0]
                    .lyne_product_section
                }
              />
            )}

            {/* <HomeFlashSection /> */}

            {data.allDirectusVenteExclusiveTranslation.nodes[0]
              .matelas_section && (
              <VentesMatelasSection
                data={
                  data.allDirectusVenteExclusiveTranslation.nodes[0]
                    .matelas_section
                }
              />
            )}

            {data.allDirectusVenteExclusiveTranslation.nodes[0]
              .matelas_product_section && (
              <VentesMatelasProductSection
                data={
                  data.allDirectusVenteExclusiveTranslation.nodes[0]
                    .matelas_product_section
                }
              />
            )}

            {data.allDirectusVenteExclusiveTranslation.nodes[0]
              .sommier_section && (
              <VentesSommierSection
                data={
                  data.allDirectusVenteExclusiveTranslation.nodes[0]
                    .sommier_section
                }
              />
            )}

            {data.allDirectusVenteExclusiveTranslation.nodes[0]
              .loop_section && (
              <VentesLoopSection
                data={
                  data.allDirectusVenteExclusiveTranslation.nodes[0]
                    .loop_section
                }
              />
            )}

            {data.allDirectusNewHomePageTranslation.nodes[0]
              .satisfaction_section && (
              <HomeSatisfactionSection
                data={
                  data.allDirectusNewHomePageTranslation.nodes[0]
                    .satisfaction_section
                }
                page="home"
              />
            )}

            {data.allDirectusNewHomePageTranslation.nodes[0].bottom_section && (
              <BottomHomeSection
                data={
                  data.allDirectusNewHomePageTranslation.nodes[0].bottom_section
                }
              />
            )}

            {data.allDirectusTvAdsSectionTranslation.nodes.length > 0 && (
              <TvAdsHomeSection
                data={data.allDirectusTvAdsSectionTranslation.nodes[0]}
                page="newHome"
              />
            )}
          </Fragment>
        )}
      {process.env &&
        (process.env.GATSBY_CURRENCY_TYPE === "cad" ||
          process.env.GATSBY_CURRENCY_TYPE === "gbp" ||
          process.env.GATSBY_CURRENCY_TYPE === "usd" ||
          (process.env.GATSBY_CURRENCY_TYPE === "eur" && intl.locale != "fr") ||
          (process.env.GATSBY_CURRENCY_TYPE === "general" &&
            intl.locale != "fr")) && (
          <Fragment>
            {(intl.locale === "fr" || intl.locale === "en") &&
              data.allDirectusNewHomePageTranslation.nodes[0] && (
                <HomeVideoBanner
                  data={data.allDirectusNewHomePageTranslation.nodes[0]}
                />
              )}

            {/* {process.env.GATSBY_CURRENCY_TYPE === "gbp" &&
              data.allDirectusNewHomePageTranslation.nodes[0] && (
                <BlackFridayHomeVideoBanner
                  data={data.allDirectusNewHomePageTranslation.nodes[0]}
                />
              )} */}

            {(intl.locale === "fr" || intl.locale === "en") &&
              data.allDirectusNewHomePageTranslation.nodes[0]
                .new_testimonial_section && (
                <TestimonialSection
                  data={
                    data.allDirectusNewHomePageTranslation.nodes[0]
                      .new_testimonial_section
                  }
                  page="home"
                />
              )}

            {(intl.locale === "de" || intl.locale === "es") &&
              data.allDirectusNewHomePageTranslation.nodes[0] && (
                <LyneHomeBanner
                  data={data.allDirectusNewHomePageTranslation.nodes[0]}
                />
              )}
            {data.allDirectusNewHomePageTranslation.nodes[0] && (
              <LyneUpHomeBanner
                data={data.allDirectusNewHomePageTranslation.nodes[0]}
              />
            )}
            {data.allDirectusNewHomePageTranslation.nodes[0]
              .product_section && (
              <ProductHomeSection
                data={
                  data.allDirectusNewHomePageTranslation.nodes[0]
                    .product_section
                }
              />
            )}
            {data.allDirectusNewHomePageTranslation.nodes[0]
              .innovation_section && (
              <InnovationHomeSection
                data={
                  data.allDirectusNewHomePageTranslation.nodes[0]
                    .innovation_section
                }
              />
            )}
            {process.env.GATSBY_ACTIVE_ENV != "gbp" &&
              data.allDirectusNewHomePageTranslation.nodes[0]
                .review_section && (
                <ReviewHomeSection
                  data={
                    data.allDirectusNewHomePageTranslation.nodes[0]
                      .review_section
                  }
                />
              )}
            {data.allDirectusTvAdsSectionTranslation.nodes.length > 0 && (
              <TvAdsSection
                data={data.allDirectusTvAdsSectionTranslation.nodes[0]}
                page="home"
              />
            )}

            {process.env.GATSBY_ACTIVE_ENV != "gbp" &&
              data.allDirectusNewHomePageTranslation.nodes[0]
                .expert_section && (
                <ExpertHomeSection
                  data={
                    data.allDirectusNewHomePageTranslation.nodes[0]
                      .expert_section
                  }
                />
              )}

            {process.env.GATSBY_ACTIVE_ENV != "gbp" &&
              data.allDirectusNewHomePageTranslation.nodes[0]
                .result_section && (
                <ResultHomeSection
                  data={
                    data.allDirectusNewHomePageTranslation.nodes[0]
                      .result_section
                  }
                />
              )}

            {data.allDirectusNewHomePageTranslation.nodes[0]
              .instagram_section && (
              <InstagramHomeSection
                data={
                  data.allDirectusNewHomePageTranslation.nodes[0]
                    .instagram_section
                }
              />
            )}

            {data.allDirectusCommonTranslation.nodes.length > 0 && (
              <Testimonials
                data={
                  data.allDirectusCommonTranslation.nodes[0].testimonials.list
                }
              />
            )}
          </Fragment>
        )}
      {data.allDirectusFooterTranslation.nodes.length > 0 && (
        <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
      )}
    </Fragment>
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusNewHomePageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        top_banner_desktop_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920, quality: 100)
            }
          }
        }
        top_banner_tablet_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 100)
            }
          }
        }
        top_banner_mobile_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        top_banner_text {
          logo_image
          top_text
          title
          text
          button_text
          button_url
        }
        lyneup_section_desktop_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920, quality: 100)
            }
          }
        }
        lyneup_section_tablet_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 100)
            }
          }
        }
        lyneup_section_mobile_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1900, quality: 100)
            }
          }
        }
        lyneup_banner_text {
          logo_image
          top_text
          title
          text
          button_text
          button_url
        }
        product_section {
          title
          text
          product_list {
            top_text
            title
            logo_image
            image
            tag
            button_text
            button_url
          }
        }
        innovation_section {
          flag_image
          medical_logo
          list {
            desktop_image
            mobile_image
            title
            text
            button_text
            button_url
          }
        }
        review_section {
          desktop_image
          mobile_image
          title
          button_text
          button_url
        }
        testimonial_section {
          desktop_image
          mobile_image
          title
          text
          button_text
          button_url
          list {
            image
            text
          }
        }
        expert_section {
          desktop_image
          mobile_image
          title
          text
          bottom_text
          logo_image
          button_text
          button_url
        }
        result_section {
          title
          text
          bottom_text
          button_text
          button_url
          list {
            title
            text
          }
        }
        instagram_section {
          title
          dekstop_image
          mobile_image
          instagram_logo
          instagram_text
          button_text
          button_url
          button_url_uk
        }
        video_section_text {
          title
          mobile_title
          button_text
          button_url
          medical_image
          innovation_image
        }
        new_testimonial_section {
          title
          subtitle
          list {
            image
            text
            mobile_text
            popup_text
          }
        }
        satisfaction_section {
          title
          subtitle
          mobile_subtitle
        }
        bottom_section {
          list {
            image
            alt
            title
            text
            button_text
            button_url
          }
        }
      }
    }
    allDirectusChristmasBannerTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        christmas_section {
          image
          alt
          title
          text
        }
        christmas_lyne_section {
          image
          mobile_image
          alt
          video
          mobile_video
          title
          text
          bottom_text
          button_text
          button_url
        }
      }
    }
    allDirectusVenteExclusiveTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        product_section {
          section_title
          list {
            image
            alt
            title
            top_text
            button_text
            button_url
          }
        }
        chaise_section {
          image
          alt
          top_image
          top_image_alt
          title
          subtitle
          text
          bottom_text
          url
        }
        lyne_section {
          image
          alt
          top_image
          top_image_alt
          logo_image
          logo_alt
          title
          text
          url
        }
        lyne_product_section {
          logo
          logo_alt
          title
          text
          top_text
          card_title
          discount_text
          url
          list {
            image
            alt
            text
            url
            color_data {
              name
              image
              url
            }
          }
        }
        matelas_section {
          image
          alt
          top_image
          top_image_alt
          title
          subtitle
          text
          bottom_text
          url
        }
        matelas_product_section {
          logo
          logo_alt
          title
          text
          bottom_text
          top_text
          url
          list {
            image
            alt
            title
            text
            discount_text
            url
          }
        }
        sommier_section {
          image
          alt
          title
          text
          discount_text
          button_text
          button_url
        }
        loop_section {
          image
          alt
          top_image
          top_image_alt
          loop_image
          loop_alt
          title
          text
          bottom_text
          button_text
          button_url
        }
      }
    }
    allDirectusGiftCardPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        gift_card_popup {
          title
          text
          list {
            image
            image_alt
            text
          }
        }
      }
    }
    allDirectusTvAdsSectionTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        title
        video_title
        love_us_video_title
        video_text
        love_us_video_text
        video_url
        slider_title
        love_us_slider_title
        slider_text
        love_us_slider_text
        page_link_text
        slider {
          list {
            image
            text
            link
          }
        }
        slider_link_text
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
        new_testimonial_section {
          title
          subtitle
          list {
            image
            text
            mobile_text
            popup_text
          }
        }
      }
    }
    allShopifyProduct(sort: { fields: handle }) {
      edges {
        node {
          handle
          priceRangeV2 {
            minVariantPrice {
              amount
            }
            maxVariantPrice {
              amount
            }
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: { language: { eq: $lang }, seo: { page_name: { eq: "home" } } }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(Home)
